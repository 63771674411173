import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import MainCore from "@/views/MainView.vue";
import VacView from "@/views/VacView.vue";

import VacAndroid from "@/views/VacAndroid.vue";
import Contacts from "@/views/Contacts.vue";
import VacASO from "@/views/VacASO.vue";
import VacManager from "@/views/VacManager.vue";


import MainCoreMobile from "@/views/MainViewMobile.vue";
import VacViewM from "@/views/VacViewM.vue";

import VacAndroidM from "@/views/VacAndroidM.vue";
import ContactsM from "@/views/ContactsM.vue";
import VacASOM from "@/views/VacASOM.vue";
import VacManagerM from "@/views/VacManagerM.vue";

import Privacy from "@/views/Redir.vue";

import Child from "@/views/Child.vue";

import PrivacyTwo from "@/views/RedirTwo.vue";
import Terms from "@/views/RedirThree.vue";

import PrivacyTwo_omego from "@/views/RedirTwo_omego.vue";
import Terms_omego from "@/views/RedirThree_omego.vue";

import UPrivacy from '@/views/UPrivacy.vue'
import UTerms from '@/views/UTerms.vue'

import SpeedTerms from "@/views/SpeedTerms.vue";
import SpeedPrivacy from "@/views/SpeedPrivacy.vue"

import Term from '@/views/Term.vue'

import TermYess from '@/views/TermYess.vue'

const routes: Array<RouteRecordRaw> = [
  
  {
    path: "/",
    name: "home1",
    component: MainCore,
  },
  {
    path: "/vacancies",
    name: "vacancies2",
    component: VacView,
  },

  {
    path: "/privacy",
    name: "Privacy3",
    component: Privacy,
  },

  {
    path: "/child-safety-standards-policy",
    name: "Child",
    component: Child,
  },


  {
    path: "/golive/privacy",
    name: "PrivacyThree4",
    component: PrivacyTwo,
  },

  {
    path: "/terms",
    name: "Terms5",
    component: Term,
  },

  {
    path: "/golive/terms",
    name: "Terms6",
    component: Term,
  },

  {
    path: "/yess/terms",
    name: "TermsYess",
    component: TermYess,
  },

  {
    path: "/omego/privacy",
    name: "PrivacyThree7",
    component: PrivacyTwo_omego,
  },

  {
    path: "/omego/terms",
    name: "Terms8",
    component: Terms_omego,
  },

  {
    path: "/speedmeet/privacy",
    name: "PrivacyTwo9",
    component: SpeedPrivacy,
  },

  {
    path: "/speedmeet/terms",
    name: "Speedetrms10",
    component: SpeedTerms,
  },


  {
    path: "/android",
    name: "vacandroid",
    component: VacAndroid,
  },

  {
    path: "/contacts",
    name: "contacts",
    component: Contacts,
  },


  {
    path: "/aso",
    name: "vacaso",
    component: VacASO,
  },
  {
    path: "/manager",
    name: "vacmanager",
    component: VacManager,
  },


  {
    path: "/un/privacy",
    name: "unnameprivacy",
    component: UPrivacy,
  },
  {
    path: "/un/terms",
    name: "unnameterms",
    component: UTerms,
  },


  {
    path: "/m",
    name: "mobile",
    component: MainCoreMobile,
  },

  {
    path: "/m/vacancies",
    name: "vacanciesm",
    component: VacViewM,
  },



  {
    path: "/m/android",
    name: "vacandroidm",
    component: VacAndroidM,
  },
  {
    path: "/m/aso",
    name: "vacasom",
    component: VacASOM,
  },
  {
    path: "/m/manager",
    name: "vacmanagerm",
    component: VacManagerM,
  },


  {
    path: "/m/contacts",
    name: "contactsm",
    component: ContactsM,
  },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
